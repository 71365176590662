export const SET_CHARGING_STATIONS = "setChargingStations";
export const SET_CHARGING_STATIONS_TOTAL = "setChargingStationsTotal";
export const SET_LOADING = "setLoading";
export const SET_CHARGING_STATION_USAGE = "setChargingStationUsage";

export default {
  [SET_CHARGING_STATIONS](state, chargingStations) {
    state.chargingStations = chargingStations;
  },
  [SET_CHARGING_STATIONS_TOTAL](state, total) {
    state.totalCount = total;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_CHARGING_STATION_USAGE](state, usages) {
    state.chargingStationUsages = usages;
    console.log('user_usages:', state.chargingStationUsages);
  },
};
