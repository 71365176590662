<template>
  <el-container class="simpleLayout">
    <!-- <el-header class="login-page-header-footer">Header</el-header> -->
    <slot />
    <!-- <el-footer class="login-page-header-footer">Footer</el-footer> -->
  </el-container>
</template>

<style scoped>
.simpleLayout {
  height: 100%;
  background-color: antiquewhite;
}
</style>
