export const SET_OWNERS = "setOwners";
export const SET_OWNERS_TOTAL = "setOwnersTotal";
export const SET_LOADING = "setLoading";
export const SET_OWNER = "setOwner";
export const SET_OWNER_CONTRACTS = "setOwnerContracts";



export default {
  [SET_OWNERS](state, owners) {
    state.owners = owners;
  },
  [SET_OWNERS_TOTAL](state, total) {
    state.totalCount = total;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
   [SET_OWNER](state, owner) {
    state.owner = owner;
  },
   [SET_OWNER_CONTRACTS](state, ownerContracts) {
    state.ownerContracts = ownerContracts;
  },
};
