import { createApp } from 'vue'
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import AppLayout from "@/layouts/AppLayout.vue";
import * as VueI18n from "vue-i18n";
import store from '@/store'
import zh_TW from "./locales/zh_TW";
import en from "./locales/en";

const init = async () => {
  const module = await import("./router");
  const router = await module.default;
  const app = createApp(App);
  const i18n = VueI18n.createI18n({
    legacy: false,
    locale: "zh_TW", // set locale
    fallbackLocale: "en", // set fallback locale
    messages: { ...zh_TW, ...en }, // set locale messages
  });
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }
  app
    .use(router)
    .use(store)
    .use(ElementPlus)
    .use(i18n)
    .component("AppLayout", AppLayout)
    .mount("#app");
};

init();