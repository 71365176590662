import auth from "../modules/auth/store";
import parkingLots from "../modules/parkingLots/store"
import owners from "../modules/owners/store"
import parkingLotsOwners from "../modules/parkingLotsOwners/store"
import chargingStations from "../modules/chargingStations/store"
import ownersProfits from "../modules/ownersProfits/store"

const storeData = {
  modules: {
    auth,
    parkingLots,
    owners,
    parkingLotsOwners,
    chargingStations,
    ownersProfits
  },
};

export default storeData.modules;