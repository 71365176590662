export const SET_USER = "setUser";
export const SET_PERMISSIONS = "setPermissions";

export default {
  [SET_USER](state, user) {
    state.user = user;
  },
  [SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions;
  },
};
