import { BaseApiService } from "./baseApiService";
import queryString from "query-string";
import moment from "moment";
import _ from "lodash";

export class ReadOnlyApiService extends BaseApiService {
  constructor(resource) {
    super(resource);
  }

  async fetch(config = {}) {
    try {
      let headers;
      let theSecret, theIv;
      console.log(" this.encrypted ->  ", this.encrypted);
      if (this.encrypted) {
        const [secret, iv, sessionKey] = await this.getEncryptInfo();
        theSecret = secret;
        theIv = iv;
        headers = this.getHeader(sessionKey);
      } else {
        headers = this.getHeader();
      }
      const {
        params: { order, ...params },
        ...option
      } = config;
      const url = this.getUrl();
      url.search = `${queryString.stringify(params, {
        arrayFormat: "bracket",
      })}${_.isUndefined(order) ? "" : "&" + order}`;
      const response = await fetch(url, {
        ...option,
        headers,
      });
      if (response.status >= 400) throw response;
      if (this.encrypted) {
        const data = (await response.json()) || {};
        const result = await this.decryptedContent(
          data.encryptData,
          theSecret,
          theIv
        );
        const json = JSON.parse(result);
        return json;
      } else {
        const data = (await response.json()) || {};
        return data;
      }
    } catch (err) {
      await this.handleErrors(err);
    }
  }

  async get(id) {
    try {
      let headers;
      let theSecret, theIv;

      if (this.encrypted) {
        const [secret, iv, sessionKey] = await this.getEncryptInfo();
        theSecret = secret;
        theIv = iv;
        headers = this.getHeader(sessionKey);
      } else {
        headers = this.getHeader();
      }
      const response = await fetch(this.getUrl(id), {
        method: "GET",
        headers: headers,
      });
      if (response.status >= 400) throw response;
      if (this.encrypted) {
        const data = (await response.json()) || {};
        const result = await this.decryptedContent(
          data.encryptData,
          theSecret,
          theIv
        );
        const json = JSON.parse(result);
        return json;
      } else {
        const data = (await response.json()) || {};
        return data;
      }
    } catch (err) {
      await this.handleErrors(err);
    }
  }

  async getFile(path = "", config = {}) {
    try {
      let headers;
      if (this.encrypted) {
        const [secret, iv, sessionKey] = await this.getEncryptInfo();
        headers = this.getHeader(sessionKey);
      } else {
        headers = this.getHeader();
      }
      delete headers["Content-Type"];
      const {
        params: { order, ...params },
        ...option
      } = config;
      const url = this.getUrl(path);
      url.search = `${queryString.stringify(params, {
        arrayFormat: "bracket",
      })}${_.isUndefined(order) ? "" : "&" + order}`;
      const response = await fetch(url, {
        ...option,
        headers,
      });
      if (response.status >= 400) throw response;
      console.log(response);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = downloadUrl;
      a.download = `導出資料${moment().format("YYYYMMDDhhmm")}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      return response;
    } catch (err) {
      console.log("err", err);
      await this.handleErrors(err);
    }
  }
}
