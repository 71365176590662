import {
  SET_PARKING_LOTS_OWNER,
  SET_PARKING_LOTS_OWNER_TOTAL,
  SET_LOADING,
  SET_OWNER,
  SET_PARKING_LOTS_OWNER_CHARGING_STATIONS,
} from "./mutations";

export default {
  async fetchParkingLots({ commit }, params = {}) {
    commit(SET_LOADING, true);
    const result = await this.$api.parkingLots.fetch({ params });
    commit(SET_PARKING_LOTS_OWNER, result?.parking_lots ?? []);
    commit(SET_PARKING_LOTS_OWNER_TOTAL, result?.parking_lots.length ?? 0);
    console.log(params);
    commit(SET_LOADING, false);
    commit(SET_OWNER, result?.owner ?? {});
  },


    
  // async createMerchandise({ commit }, params) {
  //   commit(SET_LOADING, true);
  //   const result = await this.$api.merchandise.post(params);
  //   console.log('createMerchandise result', result)
  //   commit(SET_LOADING, false);
  // },

  // async updateMerchandise({ commit }, params) {
  //   commit(SET_LOADING, true);
  //   const result = await this.$api.merchandise.put(params.id, params);
  //   console.log('updateMerchandise result', result)
  //   commit(SET_LOADING, false);
  // },

  // async deleteMerchandise({ commit }, params) {
  //   commit(SET_LOADING, true);
  //   const result = await this.$api.merchandise.delete(params.id);
  //   console.log('deleteMerchandise result', result)
  //   commit(SET_LOADING, false);
  // },
};
