export default {
  en: {
    languages: {
      en: "English",
      zh_TW: "中文",
    },
    general: {
      yes: "Yes",
      no: "No",
      please_select: "Please Select",
      please_type: "Please Type",
      warning: "Warning!",
      warningContent: "delete？",
      warningCancell: "Are you sure?",
      active: "Active",
      inactive: "Inactive",
      day: "天",
      days: "天",
    },
    nav: {
      parkingLots: "Parking Lots",
      chargingStations: "Charging Stations",
      owners: "Parking Lot Owners",
      ownersDetail: "My Contracts",
      parkingLotsOwner: "Owner Backend",
      parkingLotsOwnerList: "Parking Lot Detail",
      ownerContract: "Owner's Contract Period",
      ownerProfit: "Charging Stations' Revenue",
      monthProfit: "Month on Month Revenue",
      seasonProfit: "Quarter on Quarter Revenue",
      yearProfit: "Year on Year Revenue"
    },
    action: {
      search: "Search",
      reset: "Reset",
      add_campaign: "Create Campaign",
      create_campaign: "Create Campaign",
      edit_campaign: "Edit Campaign",
      cancel: "Cancel",
      clear_all: "Clear All",
      confirm: "Confirm",
      add: "Add",
      update: "Update",
      update_refund: "Update and Refund",
      create: "Create",
      edit: "Edit",
      save: "Save",
      export: "Export Excel",
      batch_activate: "Batch Activate",
      batch_deactivate: "Batch Deactivate",
      import: "Import",
      completed: "Completed",
      importParkingLots: "Import Parking Lots",
      importOwners: "Import Owners",
      newChargingStation: "New Charging Station"
    },
    progress: {
      all_campaigns: "All Campaigns",
      on_going: "On Going",
      pending: "Pending",
      finished: "Finished",
    },
    campaign: {
      client_name: "Client Name",
      period: "Period",
      redeem_count: "Redeem Count",
      date_range: "Date Range",
      create: "Create",
      settings: "Campaign Settings",
      company_client: "Company Client",
      campaign_time: "Time Range",
      send_date: "Sending date",
      select_send_date: "Select sending date",
      send_time_window: "Time window",
      send_expiry_time_window: "Expiration notice time window",
      select_send_time_window: "Select time window",
      merchandise_group: "Merchandise group",
      edit_merchandise_info: "Edit merchandise info",
      remove: "Remove",
      delete_warning_content:
        "Are you sure you want to remove the item from this group?",
      delete_merchandise_group: "Delete Merchandise Group",
      up: "Up",
      down: "Down",
      resend: "Resend",
      add_expiry_time_window: "Add new expiry time window",
      budget: "Budget",
      show_market_price: "Show market price",
      is_in_ad_query: "Is in ad query",
      is_paid_leave: "Paid Leave",
      merchandises: "Merchandises",
      content: "Campaign Content",
      title: "Campaign Title",
      select_merchandise: "Select Merchandises",
      merchandise_list: "List",
      campaign_added: "Campaign Created",
      morning: "Morning",
      noon: "Noon",
      night: "Night",
      merchandise_group_name: "Group name",
      input_group_name: "Please input group name",
      has_default_address: "Default Address",
      city: "city",
      district: "district",
      street: "street",
      employees_involved_in_the_campaign: "Employees involved in the campaign",
      import_result: "Import Result",
      employees_id: "Employees id",
      import_participating_employees: "Import participating employees",
      exchange_details: "Exchange Details",
      giftSelected: "Gift selected",
      tooltip: {
        in_ad_query:
          "When the event is about to start, it will be displayed on the upcoming page of the App.",
        default_address:
          "The zip code will be automatically generated by the system.",
      },
      form_error: {
        title: "Please input title form",
        content: "Please input message form",
        organization: "Please select organization",
        date_range: "Please pick an activity area",
        campaignStartNotificationDate: "Please pick a date",
        campaignStartNotificationPeriod: "Please select period of time",
        groups:
          "You must select at least one product in the product combination!",
        default_address: "The reservation address is filled in!",
        sendDateMustInDateRange:
          "Notifications must be between the campaign time period",
      },
    },
    organization: {
      company_client: "Client Name",
      client_name: "Client Name",
      client_name_en: "English Name",
      status: "Status",
      tax_id: "Tax ID",
      settings: "Company Information Settings",
      campany_name_zh: "Campany Chinese Name",
      campany_name_en: "Campany English Name",
      updated: "Organization Updated",
      added: "Organization Created",
      account_rule_tips: "Account naming rule tips",
      password_rule_tips: "Password naming rule tips",
      add_organization: "Create Organization",
      edit_organization: "Edit Organization",
      batch_activate: "Batch Activate",
      batch_deactivate: "Batch Deactivate",
      activate_confirm: "Are you sure to activate the selected organizations?",
      deactivate_confirm:
        "Are you sure to deactivate the selected organizations?",
      abbreviation_info:
        "The abbreviation will affect the employee account rules, which cannot be modified after the building materials! Please double check!",
      form_error: {
        name: "Please enter valid company name",
        name_en: "Please enter valid english company name",
        tax_id: "Please enter valid tax id",
        account_rule_tips: "Please enter account rule",
        password_rule_tips: "Please enter password rule",
      },
      tooltip: {
        abbreviation_tips: "For employee's account prefix.",
        account_rule_tips:
          "Default setting is abbreviation plus employee's id.",
        password_rule_tips:
          "For User first time login but forget about their default password.",
        password_rule_alert: "Alert: password length have to over 8",
      },
    },
    employees: {
      people: "people",
      company_client: "Client Name",
      settings: "Employee Profile Settings",
      account: "Account",
      email: "Email",
      mobile: "Mobile",
      opening_date: "Account opening date",
      creation_date: "Account creation date",
      filter_not_opened: "Is filter not opened",
      filter_disable: "Is filter disable",
      create_employee_profile: "Create Employee Profile",
      edit_employee_profile: "Edit Employee Profile",
      added: "Employee Created",
      updated: "Employee Updated",
      batch_activate: "Batch Activate",
      batch_deactivate: "Batch Deactivate",
      activate_confirm: "Are you sure to activate the selected employees?",
      deactivate_confirm: "Are you sure to deactivate the selected employees?",
      account_status: {
        suspended: "suspended",
        activated: "activated",
      },
      activation_status: {
        deactivate: "deactivate",
        activated: "activated",
      },
      table: {
        account: "Account",
        email: "Email",
        mobile: "Mobile",
        activation_status: "Activation Status",
        account_status: "Account Status",
      },
      before_upload_heads_up:
        "Before you import the data, make sure the type of the file is Excel, and within 5,000 for each.",
      excel_template_download: "Download Excel Example",
      upload_data: {
        rule_one:
          "檔案欄位依序為工號、預設密碼、電子信箱、中文姓名、身分證號、生日、到職日、地址，第一列為標題，匯入資料從第二列開始。",
        rule_two: "選擇公司後，系統進行初步檢查，無法會入者將列於異常清單。",
        rule_three:
          "匯入成功，系統將逐一新增系統帳號（帳號規則為公司代碼 - 員工功號，公司代碼由系統自動生產，無需輸入）。",
        rule_four: "請於 60 分鐘後再操作此次新增的帳號。",
        rule_five: '*欄位 "工號"、"預設密碼" is required.',
        incorrect_format_messages: "Incorrect format rows",
        upload_result: "Import results",
        upload_result_preview: "Preview import result",
        success_count: "Successful number of rows imported:",
        preview_success_count: "Number of rows will be imported: ",
        preview_user: "Imported user employeeId preview: (only first 50 rows)",
        preview_user_point: "points",
        rule_joy_fund: '* "工號" & "點數" is required.',
      },
      list_of_empolyees: "Employee List *",
      click_to_import: "Import",
      confirm_import: "Confirm Import",
      cancel_import: "Cancel Import",
      complete_import: "Complete Import",
      upload_departed_employees: {
        rule_one: "檔案欄位為工號，第一列為標題，匯入資料從第二列開始。",
        rule_two: "選擇公司後，系統進行初步檢查，無法會入者將列於異常清單。",
        rule_three: "匯入成功，系統將逐一調整人員狀態。",
      },
      form: {
        company_client: "Client Name",
        employees_id: "Employees Id",
        default_password: "Default Password",
        email: "Email",
        name: "Name",
        national_id: "National Id",
        birthday: "Birthday",
        birthday_placeholder: "Select birthday",
        on_board_at: "On Board At",
        on_board_at_placeholder: "Select on board date",
        account_status: "Account Status",
        suspended: "suspended",
        activated: "activated",
      },
      form_error: {
        organization: "Please select organization",
        employees_id: "Please input employees id",
        default_password: "Please input password",
        email: "Invalid email",
        not_select_company: "Please select company",
        missing_file: "Please upload one file",
      },
      form_success: "Success",
    },
    parkingLot: {
      mall: "Fully Mall",
      add: "Add a merchandise",
      edit: "Edit the merchandise",
      addCategory: "Add a category",
      select: "Select merchandises",
      search: "Type the keyword",
      info: "Information",
      sale_info: "Sales Information",
      image: "Images",
      market_price: "Price for User",
      price: "Price for Client",
      original_price: "Origin Price",
      category: "Category",
      select_category: "Select Category",
      seller: "Seller",
      select_seller: "Select sellers",
      type_merchandise_name: "Please type a merchandise' name",
      custom_id: "Merchandise ID",
      SKU: "SKU",
      allowedImageType: "We support jpg/jpeg/svg/png file only.",
      searchMerchandiseName: "Search Product Name",
      input_category_name: "Input Category Name",
      create_category_success: "Success",
      name: "Name",
      description: "Description",
      address: "Address",
      longitude: "Longitude",
      latitude: "Latitude",
      owner_num: "Owner Num",
    },
    chargingStation: {
      edit: 'Edit Charging Station',
      info: 'Charging Station Info',
      name: 'Charging Station Name',
      description: 'Charging Station Description',
      charge_type: 'Charge Type',
      charge_model: 'Charge Model',
      kwh_per_hour: 'Charging Station Kwh Per Hour',
    },
    order: {
      all_orders: "All Orders",
      order_no: "Order Number",
      campaign_order_no: "Campaign Order .No",
      seller_order_no: "Seller Order .No",
      type_order_no: "campaign/seller order number",
      recipient: "Recipient",
      type_recipient_name: "Please type a recipient' name",
      delivery_time_range: "Delivery Time Range",
      import: "Import tracking numbers",
      export: "Export Result",
      order_detail: "Order Detail",
      shipping_item: "Shipping Item",
      item_name: "Item name",
      quantity: "Quantiy",
      phone: "Phone",
      address: "Address",
      note: "Note",
      recipient_info: "Recipient's Information",
      shipping_info: "Shipping Information",
      tracking_number: "Tracking Number",
      no_tracking_number: "Not created yet",
      delivery_date: "Not created yet",
      update_shipping: "Update Shipping Info.",
      currier: "Currier",
      update_tracking_info_success: "Tracking Info Updated",
      update_status_success: "Status Updated",
      status: "Status",
      created: "Created",
      toBeShipped: "To be shipped",
      shipped: "Shipped",
      cancell: "Cancell",
      cancelled: "Cancelled",
      changeStatus: "Change Order Status",
      partiallyShipped: "Partially Shipped",
      returnOrderApplication: "Return Order Application",
      returnCompleted: "Return Order Completed",
    },
     owner: {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email Address",
      password: "Password",
      info: "Information",
      edit: "Edit owner's information"
    },
    table: {
      title: "Title",
      creator: "Creator",
      actions: "Actions",
      no_data: "No Data",
      edit: "Edit",
      delete: "Delete",
      resend: "Resend",
      keyword: "Keyword",
      select: "Select",
      select_client_name: "Please select a client's name",
      input_campaign_title_or_keyword: "Please type a title or a keyword",
      start_date: "Start Date",
      end_date: "End Date",
      to: "To",
      past7days: "Past 7 days",
      past30days: "Past 30 days",
      past90days: "Past 90 days",
      view_detail: "View Detail",
      loading: "Loading",
      history: "History",
    },
    login: {
      email: "Email",
      password: "Password",
      login: "Login",
      error: {
        login_failed: "Logging in failed",
        confirm: "Confirm",
      },
    },
    web: {
      comingSoon: "Coming Soon",
    }
  },
};
