export default {
  zh_TW: {
    languages: {
      en: "English",
      zh_TW: "中文",
    },
    general: {
      yes: "是",
      no: "否",
      please_select: "請選擇",
      please_type: "請輸入",
      warning: "警告!",
      warningContent: "確定刪除？",
      warningCancell: "確定取消訂單?",
      active: "正常使用中",
      inactive: "停用中",
      day: "day",
      days: "days",
    },
    nav: {
      parkingLots: "全國站點",
      chargingStations: "各充電樁輸出總電力",
      chargingStationsDetail:"充電樁明細",
      owners: "分類地主",
      ownersDetail: "我的合約列表",
      ownersProfits: "總營收",
      parkingLotsOwner: "地主後台",
      parkingLotsOwnerList: "各站點明細查詢內容",
      ownerContract: "合約列表",
      ownerProfit: "營收查詢",
      monthProfit: "月份營收查詢",
      seasonProfit: "季營收查詢",
      yearProfit: "年度營收查詢"
    },
    action: {
      search: "搜尋",
      reset: "重設",
      add_campaign: "新增訊息",
      create_campaign: "建立訊息",
      edit_campaign: "完成編輯",
      cancel: "取消",
      clear_all: "清除全部",
      confirm: "確認",
      add: "新增",
      update: "更新",
      create: "建立",
      edit: "編輯",
      save: "儲存",
      export: "匯出Excel",
      update_refund: "更新並完成退款",
      import: "匯入",
      completed: "完成",
      importParkingLots: "匯入站點",
      importOwners: "匯入營運者",
      newChargingStation: "新增充電樁"
    },
    progress: {
      all_campaigns: "全部訊息",
      on_going: "進行中",
      pending: "待進行",
      finished: "已結束",
    },
    campaign: {
      client_name: "企業客戶名稱",
      period: "活動起迄",
      redeem_count: "兌換人數",
      date_range: "活動區間",
      create: "新增訊息",
      settings: "訊息設定",
      company_client: "企業客戶",
      campaign_time: "活動時間",
      send_date: "發送時間",
      select_send_date: "請選擇發送時間",
      send_time_window: "發送時段",
      send_expiry_time_window: "到期通知發送時段",
      select_send_time_window: "請選擇發送時段",
      merchandise_group: "商品組合",
      edit_merchandise_info: "即時編輯商品資訊",
      remove: "移除",
      delete_warning_content: "確定要將商品從此組合中移除？",
      delete_merchandise_group: "刪除商品組合",
      add_expiry_time_window: "新增到期發送時段",
      budget: "預算",
      up: "上移",
      down: "下移",
      resend: "已重新發送",
      show_market_price: "是否顯示商品市價",
      is_in_ad_query: "是否加入廣告頁佇列",
      is_paid_leave: "是否可以折抵假期",
      merchandises: "參與活動的商品",
      content: "訊息內容",
      title: "訊息標題",
      select_merchandise: "選擇此區商品",
      merchandise_list: "商品清單",
      campaign_added: "已新增訊息",
      morning: "早上",
      noon: "中午",
      night: "晚間",
      merchandise_group_name: "商品組合名稱",
      input_group_name: "請輸入商品組合名稱",
      has_default_address: "是否需要預留地址",
      city: "縣 / 市",
      district: "區",
      street: "鄰 / 里 / 街 / 路",
      employees_involved_in_the_campaign: "參與活動的員工",
      import_result: "導入結果",
      employees_id: "工號",
      import_participating_employees: "導入參與員工",
      exchange_details: "兌換明細",
      giftSelected: "是否已選定好禮",
      tooltip: {
        in_ad_query: "活動快開始時，將會在顯示於App即將到來的頁面．",
        default_address: "郵遞區號將會由系統自動產生．",
      },
      form_error: {
        title: "請輸入訊息標題",
        content: "請輸入訊息內容",
        organization: "請選擇企業客戶",
        date_range: "請挑選活動區間",
        campaignStartNotificationDate: "請挑選發送時間",
        campaignStartNotificationPeriod: "請選擇發送時段",
        groups: "商品組合至少要選一個商品喔！",
        default_address: "預設地址為填齊！",
        sendDateMustInDateRange: "發送時間必須在活動時間區間內",
      },
    },
    organization: {
      company_client: "企業客戶",
      client_name: "企業客戶名稱",
      client_name_en: "英文名稱",
      status: "狀態",
      tax_id: "統一編號",
      settings: "公司資料設定",
      campany_name_zh: "公司中文名稱",
      campany_name_en: "公司英文名稱",
      abbreviation: "英文縮寫",
      added: "已新增公司",
      updated: "已編輯公司",
      account_rule_tips: "帳號規則",
      password_rule_tips: "預設密碼規則",
      add_organization: "新增公司資料",
      edit_organization: "編輯公司資料",
      batch_activate: "批次啟用",
      batch_deactivate: "批次停用",
      activate_confirm: "確定要啟用已選擇的公司?",
      deactivate_confirm: "確定要停用已選擇的公司?",
      abbreviation_info:
        "公司英文縮寫將影響到員工帳號規則，建立資料後將不能修改！ 請再仔細確認!",
      form_error: {
        name: "請輸入公司中文名稱",
        name_en: "請輸入公司英文名稱",
        tax_id: "請輸入統一編號",
        account_rule_tips: "請輸入帳號規則",
        password_rule_tips: "請輸入預設密碼規則",
        abbreviation: "請輸入英文縮寫",
      },
      tooltip: {
        abbreviation_tips:
          "此處填寫的文字將會成為每一位員工帳號的開頭前綴，送出後便不得再修改。",
        account_rule_tips: "使用者登入 App 的帳號將設定為: 英文縮寫 + 工號。",
        password_rule_tips:
          "此處填寫的文字將會顯示在，當用戶首次登入 App 卻忘記預設密碼的時候。",
        password_rule_alert: "預設密碼規則必須要大於等於八碼以上",
      },
    },
    employees: {
      people: "人",
      company_client: "企業客戶",
      settings: "人員資料設定",
      account: "人員帳號",
      email: "電子信箱",
      mobile: "手機號碼",
      opening_date: "帳號開通日期",
      creation_date: "帳號建立日期",
      filter_not_opened: "濾掉未開通",
      filter_disable: "濾掉停用",
      create_employee_profile: "新增員工資料",
      edit_employee_profile: "編輯員工資料",
      added: "已新增員工資料",
      updated: "已編輯員工資料",
      batch_activate: "批次啟用",
      batch_deactivate: "批次停用",
      activate_confirm: "確定要啟用已選擇的員工?",
      deactivate_confirm: "確定要停用已選擇的員工?",
      account_status: {
        suspended: "已停用",
        activated: "正常使用中",
      },
      activation_status: {
        deactivate: "未開通",
        activated: "已開通",
      },
      table: {
        account: "帳號",
        email: "電子信箱",
        mobile: "手機號碼",
        activation_status: "開通狀態",
        account_status: "帳號狀態",
      },
      before_upload_heads_up:
        "作業前，請先備妥人員清單 （檔案型態：Excel），匯入資料筆數建議 5000 筆。",
      excel_template_download: "Excel 範例檔下載",
      upload_data: {
        rule_one:
          "檔案欄位依序為工號、預設密碼、電子信箱、中文姓名、身分證號、生日、到職日、地址，第一列為標題，匯入資料從第二列開始。",
        rule_two: "選擇公司後，系統進行初步檢查，無法會入者將列於異常清單。",
        rule_three:
          "匯入成功，系統將逐一新增系統帳號（帳號規則為公司代碼 - 員工工號，公司代碼由系統自動生產，無需輸入）。",
        rule_four: "請於 60 分鐘後再操作此次新增的帳號。",
        rule_five: '*請注意: 欄位 "工號" 以及 "預設密碼" 為必填欄位。',
        incorrect_format_messages: "異常資料提示清單",
        upload_result: "匯入結果",
        upload_result_preview: "匯入預覽",
        success_count: "已成功匯入資料筆數:",
        preview_success_count: "即將匯入資料筆數:",
        preview_user: "即將匯入員工號預覽：（只顯示前50名）",
        preview_user_point: "享樂金點數",
        rule_joy_fund: '*請注意: 欄位 "工號" 以及 "點數" 為必填欄位。',
      },
      list_of_empolyees: "人員清單檔案 *",
      click_to_import: "匯入",
      confirm_import: "確定匯入",
      cancel_import: "取消",
      complete_import: "匯入完成",
      upload_departed_employees: {
        rule_one: "檔案欄位為工號，第一列為標題，匯入資料從第二列開始。",
        rule_two: "選擇公司後，系統進行初步檢查，無法會入者將列於異常清單。",
        rule_three: "匯入成功，系統將逐一調整人員狀態。",
      },
      form: {
        company_client: "企業客戶",
        employees_id: "工號",
        default_password: "預設密碼",
        email: "電子信箱",
        name: "中文姓名",
        national_id: "身分證號",
        birthday: "生日",
        birthday_placeholder: "請選擇生日日期",
        on_board_at: "到職日",
        on_board_at_placeholder: "請選擇到職日期",
        account_status: "帳號狀態",
        suspended: "停用",
        activated: "使用",
      },
      form_error: {
        organization: "請選擇企業客戶",
        employees_id: "請輸入員工工號",
        default_password: "請輸入預設密碼",
        email: "電子郵件格式有誤",
        not_select_company: "請選擇想導入的企業",
        missing_file: "請上傳人員清單檔案",
      },
    },
    parkingLot: {
      mall: "滿滿商城",
      add: "新增商品",
      edit: "編輯商品",
      addCategory: "新增類別",
      select: "請選擇商品",
      search: "搜尋商品關鍵字",
      info: "商品基本資訊",
      sale_info: "商品銷售資訊",
      image: "商品圖片",
      market_price: "商品市價",
      price: "商品報價",
      original_price: "商品進貨價",
      category: "商品類別",
      select_category: "請選擇商品類別",
      seller: "商家名稱",
      select_seller: "請選擇商家名稱",
      type_merchandise_name: "請輸入商品名稱",
      custom_id: "商品ID",
      SKU: "SKU",
      allowedImageType: "我們支援 jpg/jpeg/svg/png 的檔案格式",
      searchMerchandiseName: "搜尋商品名稱",
      input_category_name: "填寫類別名稱",
      create_category_success: "建立成功",
      name: "站點名稱",
      description: "站點描述",
      address: "地址",
      longitude: "經度",
      latitude: "緯度",
      owner_num: "地主編號",
    },
    chargingStation: {
      edit: '編輯充電樁',
      info: '充電樁資訊',
      name: '名稱',
      description: '描述',
      charge_type: '充電樁種類',
      charge_model: '充電樁型號',
      kwh_per_hour: '充電樁功率',
    },
    owner: {
      first_name: "名字",
      last_name: "姓氏",
      email: "信箱",
      password: "密碼",
      info: "資訊",
      edit: "編輯使用者"
    },
    order: {
      all_orders: "全部訂單",
      order_no: "訂單編號",
      campaign_order_no: "活動訂單編號",
      seller_order_no: "廠商訂單編號",
      type_order_no: "活動/廠商訂單編號",
      recipient: "收貨人資訊",
      type_recipient_name: "請輸入收貨人姓名",
      delivery_time_range: "配送時間區間",
      import: "貨運編號匯入",
      export: "匯出搜尋結果",
      order_detail: "訂單明細",
      shipping_item: "出貨商品",
      item_name: "品名",
      quantity: "數量",
      phone: "電話",
      address: "地址",
      note: "備註",
      recipient_info: "收件人資訊",
      shipping_info: "配送資訊",
      tracking_number: "配送單號",
      no_tracking_number: "尚未建立配送單號",
      delivery_date: "尚未指定派發時間",
      update_shipping: "更新物流",
      currier: "物流廠商",
      update_tracking_info_success: "物流資訊更新成功",
      update_status_success: "訂單狀態更新成功",
      status: "狀態",
      created: "已建立",
      toBeShipped: "待出貨",
      shipped: "已出貨",
      cancell: "取消訂單",
      cancelled: "已取消",
      changeStatus: "更改訂單狀態",
      partiallyShipped: "部分出貨",
      returnOrderApplication: "退貨申請中",
      returnCompleted: "退貨完成",
    },
    table: {
      title: "標題",
      creator: "建立者",
      actions: "操作",
      no_data: "沒有資料",
      edit: "編輯",
      delete: "刪除",
      resend: "重新發送",
      keyword: "關鍵字",
      select: "請選擇",
      select_client_name: "請選擇企業客戶名稱",
      input_campaign_title_or_keyword: "訊息標題或關鍵字",
      start_date: "開始日期",
      end_date: "結束日期",
      to: "到",
      past7days: "過去7天",
      past30days: "過去30天",
      past90days: "過去90天",
      view_detail: "查看明細",
      loading: "讀取中",
      history: "使用紀錄"
    },
    login: {
      email: "電子郵件",
      password: "密碼",
      login: "登入",
      error: {
        login_failed: "登入失敗",
        confirm: "確認",
      },
    },
    web: {
      comingSoon: "頁面準備中"
    }
  },
};
