import {
  SET_CHARGING_STATIONS,
  SET_CHARGING_STATIONS_TOTAL,
  SET_CHARGING_STATION_USAGE,
  SET_LOADING,
} from "./mutations";

export default {
  async fetchChargingStations({ commit }, params = {}) {
    commit(SET_LOADING, true);
    const result = await this.$api.chargingStations.fetch({ params });
    console.log('result', result);
    commit(SET_CHARGING_STATIONS, result?.charging_stations ?? []);
    commit(SET_CHARGING_STATIONS_TOTAL, result?.charging_stations.length ?? 0);
    commit(SET_LOADING, false);
  },

  async getChargingStation({ commit }, params = {}) {
    commit(SET_LOADING, true);
    const result = await this.$api.chargingStations.get(params.id);
    commit(SET_CHARGING_STATION_USAGE, result?.data?.user_usages ?? []);
    console.log(result);
    commit(SET_LOADING, false);
  },

  async deleteChargingStation({ commit }, params) {
    commit(SET_LOADING, true);
    const result = await this.$api.chargingStations.delete(params.id);
    console.log('deleteChargingStation result', result)
    commit(SET_LOADING, false);
  },
};
