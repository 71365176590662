export const SET_PARKING_LOTS_OWNER = "setParkingLots";
export const SET_PARKING_LOTS_OWNER_TOTAL = "setParkingLotsTotal";
export const SET_LOADING = "setLoading";
export const SET_OWNER = "setOwner";
export const SET_PARKING_LOTS_OWNER_CHARGING_STATIONS = "setParkingLot";

export default {
  [SET_PARKING_LOTS_OWNER](state, parkingLots) {
    state.parkingLots = parkingLots;
  },
  [SET_PARKING_LOTS_OWNER_TOTAL](state, total) {
    state.totalCount = total;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_OWNER](state, owner) {
    state.owner = owner;
  },
   [SET_PARKING_LOTS_OWNER_CHARGING_STATIONS](state, parkingLot) {
    state.parkingLot = parkingLot;
  },
};
