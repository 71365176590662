const PKEY =
  "-----BEGIN PUBLIC KEY-----\n\
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA1orWsoUHAre11Uhx1pZD\n\
BO/fG6xb9OB32EV0D2dATAWez+42SGvR7NgNOZTV6BOm26nT/WvqwCTK9AxqXTCZ\n\
fqePihJgO5cjdv3Oqx5EICXT3vi9ApUnSgoSIYPuVBSm419kXZJTJ62dWqDSjyCA\n\
1HXemcTRRTLDZZ3a/RacNqSJ+RBmQsPzybvixmz5C2bMBHHVSj8489mvEGlGCFPS\n\
bt/4hKoz8GNJ9N8bFUNuwDXpxirJiyB7OTYSStsfa4T8an/W3H6mbo4tl7x+F6An\n\
ab/CLFVOuLVuNZgCw5ir0zzHdVZsgw9fb7oKmLUJ8NPP6JjfFxvNoRZVzO0gVHz/\n\
bynoKmSBhiIduxKz8OrFfp2HoOgbqrYMbg9xuWZ0CQ5gnTX561BzGV0g11aBPRUW\n\
HONln9VKnCQImIecMdjMz2zrMJa6QHAFABdG/R5SGuZB9quvXTjXkleQk+c3xg7H\n\
k9/PkqkYElyYW+BecZQVxB+pHdSxOfxmi57bmerFzJGJBrRI9jb1G9ir8KSmFwaC\n\
uu/Q1fvU9b61x7PjF498PVJScT4koo+XA0gQ4LSbkjb6qhXd0XrPOp08+8ZF3KlV\n\
9GPh3ToWSCVI6dUvu8KH0VtQS6ABRdIlxfhBA2/bIrLfvKrda3M4LeuzY7pFoqMd\n\
l/zBbSgzsdh4txyUmsEKWDECAwEAAQ==\n\
-----END PUBLIC KEY-----";

import { createECDH } from "browser-crypto";
import * as CryptoJS from "crypto-js";
import { JSEncrypt } from "jsencrypt/lib/JSEncrypt";
import _ from "lodash"
import { ElMessage } from "element-plus";

export class BaseApiService {
  baseUrl = process.env.VUE_APP_BASEURL;
  encrypted = process.env.VUE_APP_API_ENCRYPTED === "true";
  resource;
  linkHeader;
  theSecret;
  theIv;

  constructor(resource) {
    if (!resource) throw new Error("Resource is not provided");
    this.resource = resource;
    this.setHeaderForLink();
    console.log(" on init this.encrypted ->  ", this.encrypted);
  }

  convertUint8_to_hexStr(uint8) {
    return Array.from(uint8)
      .map((i) => i.toString(16).padStart(2, "0"))
      .join("");
  }

  async sessionKey(secret, iv) {
    const secretHex = this.convertUint8_to_hexStr(secret);
    const ivHex = this.convertUint8_to_hexStr(iv);
    return this.encryptKey(`${secretHex},${ivHex}`);
  }

  async encryptKey(content) {
    console.log(" encrypting key....... ");
    let encryptor = new JSEncrypt();
    encryptor.setPublicKey(PKEY);
    const result = encryptor.encrypt(content);
    return result;
  }

  async encryptedContent(data, secret, iv) {
    var thekey = CryptoJS.enc.Hex.parse(this.convertUint8_to_hexStr(secret));
    var theiv = CryptoJS.enc.Hex.parse(this.convertUint8_to_hexStr(iv));
    const ciphertext = CryptoJS.AES.encrypt(data, thekey, {
      iv: theiv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();

    return JSON.stringify({
      encryptData: ciphertext,
    });
  }

  async decryptedContent(data, secret, iv) {
    // // Decrypt
    var thekey = CryptoJS.enc.Hex.parse(this.convertUint8_to_hexStr(secret));
    var theiv = CryptoJS.enc.Hex.parse(this.convertUint8_to_hexStr(iv));

    const originalText = CryptoJS.AES.decrypt(data, thekey, {
      iv: theiv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);

    console.log(`After decrypt => ${originalText}`);
    return originalText;
  }

  async getEncryptInfo() {
    const ecdh1 = createECDH("secp256k1");
    const publicKey = ecdh1.generateKeys();
    const secret = ecdh1.computeSecret(publicKey);
    const iv = new Uint8Array(16);
    crypto.getRandomValues(iv);
    const sessionKey = await this.sessionKey(secret, iv);
    console.log(" session key ->  ", sessionKey);
    return [secret, iv, sessionKey];
  }

  async decryptedLinkResponse(response) {
    try {
      if (this.encrypted) {
        const result = await this.decryptedContent(
          response.encryptData,
          this.theSecret,
          this.theIv
        );
        const json = JSON.parse(result);
        return json;
      } else {
        return response;
      }
    } catch (error) {
      console.log('error ===> ', error);
      this.handleErrors(error)
    }
  }

  async setHeaderForLink() {
    const headers = {};
    if (this.encrypted) {
      const [secret, iv, sessionKey] = await this.getEncryptInfo();
      this.theSecret = secret;
      this.theIv = iv;
      headers["treenet-token"] = sessionKey;
    }
    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    this.linkHeader = headers;
  }

  getHeader(sessionKey) {
    const headers = {
      "Content-Type": "application/json",
    };
    if (sessionKey) {
      headers["treenet-token"] = sessionKey;
    }
    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    return headers;
  }

  getUrl(id) {
    return new URL(`${this.baseUrl}/${this.resource}${_.isUndefined(id) ? '' : '/' + id}`);
  }

  handleAjaxErrors(err) {
    let error = { message: 'UNKNOW' };
    try {
      error = JSON.parse(err.message);
    } catch (e) {
      console.log(e);
    }
    ElMessage({
      message: error.message,
      type: "error",
    });

  }

  async handleErrors(err) {
    // Note: here you may want to add your errors handling
    if (err.status === 401 && location.pathname != "/login")
      location.href = "login";
    const data = (await err.json()) || {};
    if (typeof data?.message == 'string') {
      ElMessage({
        message: data.message,
        type: "error",
      });
    }
    throw err;
  }
}
