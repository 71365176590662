<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElLoading } from "element-plus";

import SimpleLayout from "./SimpleLayout.vue";

const layout = ref(SimpleLayout);
const route = useRoute();
const defineLayout = async (meta) => {
  try {
    const component = await import(`@/layouts/${meta.layout}.vue`);
    layout.value = component?.default;
  } catch (e) {
    layout.value = SimpleLayout;
  }
};
watch(() => route.meta, defineLayout, { immediate: true });

const store = useStore();
const loadingDialog = ref(undefined);
const swtichDialog = (loading) => {
  if (loading) {
    loadingDialog.value = ElLoading.service({ fullscreen: true });
  } else if (loadingDialog.value) {
    loadingDialog.value.close();
  }
};
watch(() => store.state.loading, swtichDialog);
</script>
