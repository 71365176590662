<template>
  <el-config-provider :locale="elLocale">
    {{ locale }}
    <div id="app">
      <AppLayout>
        <router-view />
      </AppLayout>
    </div>
  </el-config-provider>
</template>

<script setup>
import { watch, ref } from "vue";

import { useStore } from "vuex";
import zhTw from "element-plus/dist/locale/zh-tw.mjs";
import en from "element-plus/dist/locale/en.mjs";

const elLocale = ref(zhTw);

const store = useStore();
watch(store.state.system, (first, second) => {
  elLocale.value = second.locale === "zh_TW" ? zhTw : en;
});
</script>

<script>
import { defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";

export default defineComponent({
  components: {
    ElConfigProvider,
  },
});
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
</style>
