import { AuthApiService } from "./services/authApiService";
import { ParkingLotApiService } from "./services/parkingLotApiService";
import { ChargingStationApiService } from "./services/chargingStationApiService";
import { OwnerApiService } from "./services/ownerApiService";

export const $api = {
  auth: new AuthApiService(),
  parkingLots: new ParkingLotApiService(),
  owners: new OwnerApiService(),
  chargingStations: new ChargingStationApiService(),
};
