export const SET_PARKING_LOTS = "setParkingLots";
export const SET_PARKING_LOTS_TOTAL = "setParkingLotsTotal";
export const SET_LOADING = "setLoading";
export const SET_PARKING_LOT = "setParkingLot";

export default {
  [SET_PARKING_LOTS](state, parkingLots) {
    state.parkingLots = parkingLots;
  },
  [SET_PARKING_LOTS_TOTAL](state, total) {
    state.totalCount = total;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_PARKING_LOT](state, parkingLot) {
    state.parkingLot = parkingLot;
    state.chargingStations = parkingLot.chargingStations;
    console.log('state', state.parkingLot);
    console.log('state charging', state.chargingStations);
  }
};
