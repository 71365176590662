import {
  SET_OWNERS,
  SET_OWNERS_TOTAL,
  SET_LOADING,
  SET_OWNER,
  SET_OWNER_CONTRACTS
} from "./mutations";

export default {
  async fetchOwners({ commit }, params = {}) {
    commit(SET_LOADING, true);
    const result = await this.$api.owners.fetch({ params });
    console.log(result);
    commit(SET_OWNERS, result?.owners ?? []);
    commit(SET_OWNERS_TOTAL, result?.owners.length ?? 0);
    commit(SET_LOADING, false);
  },

  async getOwner({ commit }, params = {}) {
    commit(SET_LOADING, true);
    const result = await this.$api.owners.get(params.id);
    console.log('result:', result);
     commit(SET_OWNER_CONTRACTS, result?.data?.owner_contracts ?? []);
    console.log(result);
    commit(SET_LOADING, false);
  },

  async importOwners({ commit }, params) {
    commit(SET_LOADING, true);
    console.log(" ---> params", params);
    const { data } = await this.$api.owners.postFile(params, "/import");
    console.log('data: ', data);
    commit(SET_LOADING, false);

    return data;
  },


  // async createMerchandise({ commit }, params) {
  //   commit(SET_LOADING, true);
  //   const result = await this.$api.merchandise.post(params);
  //   console.log('createMerchandise result', result)
  //   commit(SET_LOADING, false);
  // },

  async updateOwner({ commit }, params) {
    commit(SET_LOADING, true);
    const result = await this.$api.owners.put(params.id, params);
    console.log('updateOwner result', result)
    commit(SET_LOADING, false);
  },

  async deleteOwner({ commit }, params) {
    commit(SET_LOADING, true);
    const result = await this.$api.owners.delete(params.id);
    console.log('deleteOwner result', result)
    commit(SET_LOADING, false);
  },

};
