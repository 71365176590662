import { SET_USER } from "./mutations";
import { SET_PERMISSIONS } from "./mutations";
import { SET_LOADING } from "@/store/mutationsTypes";

const mockUser = {
  id: 1,
  name: "mock user",
  age: "18",
  email: "mock email",
};

export default {
  async signIn({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(SET_LOADING, true, { root: true });
      this.$api.auth
        .post(params, "/sign_in")
        .then((data) => {
          if (data.accessToken) {
            localStorage.setItem("token", data.accessToken);
            const obj = data.permissions.reduce(
              (acc, cur) => ({ ...acc, [cur]: true }),
              {}
            );

            commit(SET_PERMISSIONS, obj);
          }
          commit(SET_USER, data.user);
          commit(SET_LOADING, false, { root: true });
          resolve();
        })
        .catch((error) => {
          console.log('this error: ', error)
          reject(error);
          commit(SET_LOADING, false, { root: true });
        });
    });
  },
  async refreshToken({ commit }, params) {
    return new Promise((resolve, reject) => {
      this.$api.auth
        .post(params, "/verify_admin_token")
        .then((result) => {
          const data  = result;
          if (data.accessToken) {
            localStorage.setItem("token", data.accessToken);
            localStorage.setItem("user_id", data.id);
            const obj = data.permissions.reduce(
              (acc, cur) => ({ ...acc, [cur]: true }),
              {}
            );
            commit(SET_PERMISSIONS, obj);

            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchUser: ({ commit }) =>
    new Promise((resolve, reject) => {
      commit(SET_LOADING, true, { root: true });
      setTimeout(() => {
        commit(SET_USER, mockUser);
        commit(SET_LOADING, false, { root: true });
        resolve();
      }, 2000);
    }),
};
