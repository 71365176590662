import { ReadOnlyApiService } from "./readOnlyApiService";

export class ModelApiService extends ReadOnlyApiService {
  constructor(resource) {
    super(resource);
  }

  async post(params = {}, path = "") {
    console.log('this.getUrl(): ', this.getUrl());
    try {
      let headers;
      let theSecret, theIv;

      if (this.encrypted) {
        const [secret, iv, sessionKey] = await this.getEncryptInfo();
        theSecret = secret;
        theIv = iv;
        headers = this.getHeader(sessionKey);
      } else {
        headers = this.getHeader();
      }
      const response = await fetch(this.getUrl() + path, {
        method: "POST",
        body: this.encrypted
          ? await this.encryptedContent(
              JSON.stringify(params),
              theSecret,
              theIv
            )
          : JSON.stringify(params),
        headers: headers,
      });
      if (response.status >= 400) throw response;
      if (this.encrypted) {
        const data = (await response.json()) || {};
        const result = await this.decryptedContent(
          data.encryptData,
          theSecret,
          theIv
        );
        const json = JSON.parse(result);
        return json.data;
      } else {
        const { data } = (await response.json()) || {};
        return data;
      }
    } catch (err) {
      console.log('model api service error: ', err);
      await this.handleErrors(err);
    }
  }

  async postFile(formdata, path = "") {
    try {
      let headers;
      let theSecret, theIv;
      if (this.encrypted) {
        const [secret, iv, sessionKey] = await this.getEncryptInfo();
        theSecret = secret;
        theIv = iv;
        headers = this.getHeader(sessionKey);
      } else {
        headers = this.getHeader();
      }
      delete headers["Content-Type"];
      const response = await fetch(this.getUrl() + path, {
        method: "POST",
        body: formdata,
        headers,
      });
      if (response.status >= 400) throw response;
      if (this.encrypted) {
        const data = (await response.json()) || {};
        const result = await this.decryptedContent(
          data.encryptData,
          theSecret,
          theIv
        );
        const json = JSON.parse(result);
        return json;
      } else {
        const data = (await response.json()) || {};
        return data;
      }
    } catch (err) {
      await this.handleErrors(err);
    }
  }

  async put(id, data = {}) {
    if (id == null) throw Error("Id is not provided");
    try {
      let headers;
      let theSecret, theIv;

      if (this.encrypted) {
        const [secret, iv, sessionKey] = await this.getEncryptInfo();
        theSecret = secret;
        theIv = iv;
        headers = this.getHeader(sessionKey);
      } else {
        headers = this.getHeader();
      }
      console.log("put ----> ");
      console.log(this.getUrl(id));
      const response = await fetch(this.getUrl(id), {
        method: "PUT",
        body: this.encrypted
          ? await this.encryptedContent(JSON.stringify(data), theSecret, theIv)
          : JSON.stringify(data),
        headers: headers,
      });
      if (response.status >= 400) throw response;
      if (this.encrypted) {
        const data = (await response.json()) || {};
        const result = await this.decryptedContent(
          data.encryptData,
          theSecret,
          theIv
        );
        const { id: responseId } = JSON.parse(result);
        return responseId;
      } else {
        const { id: responseId } = response.json();
        return responseId;
      }
    } catch (err) {
      await this.handleErrors(err);
    }
  }

  async putFile(id, formdata) {
    if (!id) throw Error("Id is not provided");
    let headers;
    let theSecret, theIv;

    if (this.encrypted) {
      const [secret, iv, sessionKey] = await this.getEncryptInfo();
      theSecret = secret;
      theIv = iv;
      headers = this.getHeader(sessionKey);
    } else {
      headers = this.getHeader();
    }
    delete headers["Content-Type"];
    try {
      const response = await fetch(this.getUrl(id), {
        method: "PUT",
        body: formdata,
        headers,
      });
      if (response.status >= 400) throw response;
      if (this.encrypted) {
        const data = (await response.json()) || {};
        const result = await this.decryptedContent(
          data.encryptData,
          theSecret,
          theIv
        );
        const { id: responseId } = JSON.parse(result);
        return responseId;
      } else {
        const { id: responseId } = response.json();
        return responseId;
      }
    } catch (err) {
      await this.handleErrors(err);
    }
  }

  async delete(id) {
    if (!id) throw Error("Id is not provided");
    try {
      let headers;
      if (this.encrypted) {
        const [secret, iv, sessionKey] = await this.getEncryptInfo();
        headers = this.getHeader(sessionKey);
      } else {
        headers = this.getHeader();
      }
      await fetch(this.getUrl(id), {
        method: "DELETE",
        headers: headers,
      });
      return true;
    } catch (err) {
      await this.handleErrors(err);
    }
  }
}
