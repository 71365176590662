import {
  SET_PARKING_LOT,
  SET_PARKING_LOTS,
  SET_PARKING_LOTS_TOTAL,
  SET_LOADING,
} from "./mutations";

export default {
  async fetchParkingLots({ commit }, params = {}) {
    commit(SET_LOADING, true);
    const result = await this.$api.parkingLots.fetch({ params });
    commit(SET_PARKING_LOTS, result?.parking_lots ?? []);
    commit(SET_PARKING_LOTS_TOTAL, result?.parking_lots.length ?? 0);
    commit(SET_LOADING, false);
  },

  async getParkingLot({ commit }, params = {}) {
    commit(SET_LOADING, true);
    const result = await this.$api.parkingLots.get(params.id);
    commit(SET_PARKING_LOT, result?.parking_lot ?? {});
    commit(SET_LOADING, false);
  },

  async importParkingLots({ commit }, params) {
    commit(SET_LOADING, true);
    console.log(" ---> params", params);
    const { data } = await this.$api.parkingLots.postFile(params, "/import");
    console.log('data: ', data);
    commit(SET_LOADING, false);

    return data;
  },

  // async createMerchandise({ commit }, params) {
  //   commit(SET_LOADING, true);
  //   const result = await this.$api.merchandise.post(params);
  //   console.log('createMerchandise result', result)
  //   commit(SET_LOADING, false);
  // },

  // async updateMerchandise({ commit }, params) {
  //   commit(SET_LOADING, true);
  //   const result = await this.$api.parkingLots.put(params.id, params);
  //   console.log('updateParkingLots result', result)
  //   commit(SET_LOADING, false);
  // },

    async updateParkingLot({ commit }, params) {
    commit(SET_LOADING, true);
    const result = await this.$api.parkingLots.put(params.id, params);
    console.log('updateParkingLot result', result)
    commit(SET_LOADING, false);
  },

  async deleteParkingLot({ commit }, params) {
    commit(SET_LOADING, true);
    const result = await this.$api.parkingLots.delete(params.id);
    console.log('deleteParkingLot result', result)
    commit(SET_LOADING, false);
  },
};
